import { JobSearch } from '@borg/types';

const items = ref<JobSearch[]>([]);

const injectionKey: InjectionKey<ReturnType<typeof useHistory>> = Symbol('history');

export function provideHistory() {
  const history = useHistory();
  provide(injectionKey, history);
  return history;
}

export function injectHistory() {
  const history = inject(injectionKey);

  if (!history) {
    throw new Error('History not provided');
  }

  return history;
}

function useHistory() {
  const userStore = useUserStore();

  async function init() {
    if (userStore.isAuthenticated) {
      const result = await historyService.getSearchHistory();

      if (result.isSuccess) {
        items.value = result.data;
      }
    } else {
      items.value = [];
    }
  }

  watchEffect(() => {
    if (userStore.isAuthenticated) {
      init();
    }
  });

  return reactive({
    items,
    init,
  });
}
