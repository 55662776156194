import validate from "/app/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@unocss+reset@0.60.2_eslint@8.54.0_floating-vue@5.2.2_ro_bouldzzfoti3i7dgyvmeabpoae/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45layout_45global from "/app/apps/site/middleware/01.layout.global.ts";
import _02_45gtag_45reset_45global from "/app/apps/site/middleware/02.gtag-reset.global.ts";
import _03_45info_45pages_45global from "/app/apps/site/middleware/03.info-pages.global.ts";
import manifest_45route_45rule from "/app/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@unocss+reset@0.60.2_eslint@8.54.0_floating-vue@5.2.2_ro_bouldzzfoti3i7dgyvmeabpoae/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45layout_45global,
  _02_45gtag_45reset_45global,
  _03_45info_45pages_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "track-page-visit-and-seen": () => import("/app/apps/site/layers/Job/middleware/track-page-visit-and-seen.ts"),
  auth: () => import("/app/apps/site/middleware/auth.ts"),
  search: () => import("/app/apps/site/middleware/search.ts")
}