<script lang="ts">
  import { useAsyncModalState } from '@borg/ui';
  import { AuthFormConfig } from './useAuthForm';

  const state = useAsyncModalState<boolean>();
  const modalConfig = ref<Partial<AuthFormConfig>>({});

  export function openAuthFormModal(config: Partial<AuthFormConfig>) {
    modalConfig.value = config;
    return state.open();
  }
</script>

<script setup lang="ts">
  import { MpModal } from '@borg/ui';
  import { injectAuthForm } from './useAuthForm';

  const authForm = injectAuthForm();
  const userStore = useUserStore();

  const isWorking = computed(
    () => userStore.loginState === 'inProgress' || userStore.registerState === 'inProgress',
  );

  function onUpdateIsOpen(value: boolean) {
    if (!value) {
      authForm.reset();
      if (isWorking.value) {
        return;
      }
    }
    state.close(value);
  }

  function onModalOpen() {
    authForm.reset();
    authForm.setConfig(modalConfig.value);
  }

  function onSuccess() {
    state.close(true);
    window.location.reload();
  }
</script>

<template>
  <MpModal
    :is-open="state.isOpen"
    @open="onModalOpen"
    @update:is-open="onUpdateIsOpen"
  >
    <AuthForm @success="onSuccess" />
  </MpModal>
</template>
