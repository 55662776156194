<script setup lang="ts">
  import { MpButton, MpInput, MpPassword, MpText, pushToastMessage } from '@borg/ui';
  import { LoginUserSchema, MIN_PASSWORD_LENGTH } from '@borg/validation';
  import { injectAuthForm } from '../useAuthForm';

  const { t } = useI18n();

  const emit = defineEmits<{
    (event: 'success'): void;
  }>();

  const { $gtag } = useNuxtApp();
  const userStore = useUserStore();
  const authForm = injectAuthForm();
  const { state, values, validate, resetStates } = useForm({
    initialData: () => ({
      email: authForm.data.email,
      password: authForm.data.password,
    }),
    schema: LoginUserSchema,
  });

  const isLoading = computed(
    () =>
      userStore.loginState === 'inProgress' ||
      userStore.initState === 'inProgress' ||
      userStore.registerState === 'inProgress',
  );

  async function onSubmit() {
    if (await validate()) {
      await userStore.login({ ...values });

      if (userStore.loginState === 'isSuccess' && userStore.isAuthenticated) {
        $gtag?.logUser('auth:login');
        emit('success');
      } else if (userStore.loginState === 'hasError') {
        pushToastMessage({
          type: 'alert',
          title: 'Server Error',
          subtitle: 'Error while logging in.',
        });
      }
    }
  }

  const update = useDebounceFn((data: typeof values) => {
    authForm.setData(data);
  }, 500);

  onUnmounted(() => {
    userStore.authError = undefined;
    resetStates();
  });

  watch(values, update);
</script>

<template>
  <form
    class="form"
    @submit.prevent="onSubmit"
  >
    <MpInput
      v-model="values.email"
      required
      :label="$t('SIGN_UP_PAGE.LOGIN_EMAIL_LABEL')"
      :placeholder="$t('SIGN_UP_PAGE.LOGIN_EMAIL_PLACEHOLDER')"
      :error="state.email.message ? t(state.email.message) : undefined"
      size="lg"
      autocomplete="username"
      test="email"
    />
    <MpPassword
      v-model="values.password"
      required
      :label="$t('SIGN_UP_PAGE.LOGIN_PASSWORD_LABEL')"
      :placeholder="$t('SIGN_UP_PAGE.LOGIN_PASSWORD_PLACEHOLDER')"
      :error="
        state.password.message ? t(state.password.message, { n: MIN_PASSWORD_LENGTH }) : undefined
      "
      size="lg"
      autocomplete="current-password"
      test="password"
    />
    <div class="wide">
      <MpText
        no-margin
        link
        semibold
        :link-disabled="isLoading"
        style="text-align: end"
        test="login-form-forgotten-password-tab-selector"
        @click="authForm.setTab('forgotten_password')"
      >
        {{ $t('SIGN_UP_PAGE.LOGIN_FORGOT_PASSWORD_BUTTON_LABEL') }}
      </MpText>
    </div>
    <MpText
      v-if="userStore.authError === 'NOT_FOUND'"
      class="form__invalid-credentials"
      no-margin
    >
      {{ $t('SIGN_UP_PAGE.LOGIN_NOT_FOUND_ERROR') }}
    </MpText>
    <MpText
      v-else-if="userStore.authError === 'INVALID_CREDENTIALS'"
      class="form__invalid-credentials"
      no-margin
    >
      {{ $t('SIGN_UP_PAGE.LOGIN_INVALID_CREDENTIALS_ERROR') }}
    </MpText>
    <MpText
      v-else-if="userStore.authError === 'LOGIN_ERROR'"
      class="form__invalid-credentials"
      no-margin
    >
      {{ $t('SIGN_UP_PAGE.LOGIN_ERROR') }}
    </MpText>
    <AuthPartsDisclaimer :text="$t('SIGN_UP_PAGE.DISCLAIMER.PREFIX_LOGIN')" />
    <MpButton
      type="submit"
      size="lg"
      :text="$t('SIGN_UP_PAGE.LOGIN_BUTTON_LABEL')"
      :is-loading="isLoading"
      :disabled="isLoading"
      test="login-form-submit-button"
    />
  </form>
</template>

<style scoped lang="scss">
  .form {
    display: flex;
    flex-direction: column;
    gap: var(--mp-space-50);

    &__invalid-credentials {
      color: var(--mp-color-status-error);
      text-align: center;
    }
  }

  .wide {
    display: flex;
    justify-content: flex-end;
  }
</style>
