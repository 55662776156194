import { ChangeEmail, ChangePassword, User } from '@borg/types';

class UserService {
  getSelf = () => serviceHttpGetFactory<User>('/me');
  patchSelf = (data: Partial<User>) => serviceHttpPatchFactory<User>('/me', data);
  deleteSelf = () => serviceHttpPostFactory<void>('/me/deactivate');
  resendVerificationLink = (data: { email: string }) =>
    serviceHttpPostFactory<void>('/me/resend-verification', data);
  changeEmail = (data: ChangeEmail) => serviceHttpPostFactory<User>('/me/change-email', data);
  changePassword = (data: ChangePassword) => serviceHttpPutFactory<void>('/me/password', data);
}

export const userService = new UserService();
