import { isString } from '@borg/utils';
import { logger } from '@borg/core/utils/logger';

type ScriptInjectionStatus = 'present' | 'loaded' | 'error' | 'unable';
const GOOGLE_GSI_SCRIPT = 'https://accounts.google.com/gsi/client';

async function createGoogleGsiClientScript(): Promise<ScriptInjectionStatus> {
  const scriptLoaded =
    !!document.querySelector(`script[src*="${GOOGLE_GSI_SCRIPT}"]`) &&
    typeof google !== 'undefined';

  return new Promise<ScriptInjectionStatus>((resolve) => {
    if (!scriptLoaded) {
      logger.info('Cannot find Google GSI client script. Loading...');
      const script: HTMLScriptElement = document.createElement('script');
      script.src = GOOGLE_GSI_SCRIPT;
      script.onload = () => {
        logger.info(`Google GSI client script loaded`);
        resolve('loaded');
      };
      script.onerror = (event) => {
        const error = isString(event) ? event : event.type;
        logger.info(`Google GSI client script load error: ${error}`);
        resolve('error');
      };
      document.head.appendChild(script);
    } else {
      resolve('present');
    }
  });
}

export async function checkGoogleGsiClientScript() {
  const scriptStatus = await createGoogleGsiClientScript();
  return scriptStatus !== 'error';
}

export async function initGoogleOneTapLogin() {
  const projectConfig = useProjectConfig();
  const userStore = useUserStore();

  if (!userStore.isAuthenticated) {
    const scriptPresent = await checkGoogleGsiClientScript();
    if (scriptPresent) {
      google.accounts.id.initialize({
        client_id: projectConfig.googleClientId,
        callback: handleGoogleCredentialResponse,
        cancel_on_tap_outside: false,
      });
      google.accounts.id.prompt();
    }
  }
}

async function handleGoogleCredentialResponse(response: google.accounts.id.CredentialResponse) {
  const { $gtag } = useNuxtApp();
  const userStore = useUserStore();
  await userStore.googleLogin({ accessToken: response.credential });
  if (userStore.isAuthenticated) {
    $gtag?.logUser('auth:google-one-tap');
    window.location.reload();
  }
}
