<script setup lang="ts">
  const props = withDefaults(
    defineProps<{
      image: string;
      maxWidth?: string;
      maxHeight?: string;
    }>(),
    {
      maxWidth: undefined,
      maxHeight: undefined,
    },
  );

  const route = useRoute();
  const getRouteBaseName = useRouteBaseName();
  const images = useImages();
  const siteTheme = useSiteTheme();
  const imageRoute = computed<string>(() => getRouteBaseName(route) ?? 'default');

  const sources = computed(() => {
    const image = images[props.image]
      ? images[props.image][imageRoute.value]
        ? images[props.image][imageRoute.value]
        : images[props.image]['default']
      : undefined;

    const desktopLight = image?.desktopLight;
    const mobileLight = image?.mobileLight ?? desktopLight;

    const desktopDark = image?.desktopDark ?? desktopLight;
    const mobileDark = image?.mobileDark ?? desktopDark ?? desktopLight;

    if (siteTheme.theme === 'light') {
      if (desktopLight !== mobileLight) {
        return {
          desktop: desktopLight,
          mobile: mobileLight,
        };
      } else {
        return {
          site: desktopLight,
        };
      }
    } else {
      if (desktopDark !== mobileDark) {
        return {
          desktop: desktopDark,
          mobile: mobileDark,
        };
      } else {
        return {
          site: desktopDark,
        };
      }
    }
  });
</script>

<template>
  <img
    v-for="(src, key) in sources"
    :key="src"
    :class="{
      [`illustration`]: true,
      [`illustration--${key}`]: true,
    }"
    v-bind="$attrs"
    loading="lazy"
    :src="src"
    :style="{
      maxWidth: props.maxWidth,
      maxHeight: props.maxHeight,
    }"
    :alt="`${image}-${key}`"
  />
</template>

<style scoped lang="scss">
  .illustration {
    &--mobile {
      @include screen-lg {
        display: none;
      }
    }

    &--desktop {
      display: none;

      @include screen-lg {
        display: initial;
      }
    }
  }
</style>
