import { NitroFetchOptions, NitroFetchRequest } from 'nitropack';
import { isObject, isString } from '@borg/utils';

const baseApiFetch = $fetch.create({
  baseURL: process.server ? 'http://localhost:3000/api/proxy' : '/api/proxy',
});

export function apiFetch<T>(
  request: NitroFetchRequest,
  options?: NitroFetchOptions<NitroFetchRequest>,
) {
  const tokenCookie = useCookie('token').value;
  const tokenState = useToken().value;
  const token = tokenCookie ?? tokenState;
  const config = useRuntimeConfig();
  const { locale } = useNuxtApp().$i18n;

  const nextOptions: NitroFetchOptions<NitroFetchRequest> = {
    ...options,
    headers: {
      ...options?.headers,
      Cookie: `token=${token}`,
      'x-app': config.public.project.domain,
      'Accept-Language': locale.value,
    },
  };

  return baseApiFetch<T>(request, nextOptions);
}

export function getApiFetchErrorMessage(error: unknown, fallbackMessage?: string) {
  if (
    isObject(error) &&
    'data' in error &&
    isObject(error.data) &&
    'message' in error.data &&
    isString(error.data.message)
  ) {
    return error.data.message;
  }
  return fallbackMessage ?? 'Unknown error';
}
