import { MpTheme } from '@borg/ui';
import { useUserStore } from '@/stores/user.store';

export function useSiteTheme() {
  const userStore = useUserStore();
  const oneYear = 60 * 60 * 24 * 365;

  const theme = useState<MpTheme>('theme', () => {
    const cookieTheme = useCookie('theme', { maxAge: oneYear, path: '/', sameSite: 'lax' });
    return (cookieTheme.value as MpTheme | undefined) ?? 'light';
  });

  function set(nextTheme?: MpTheme) {
    if (nextTheme) {
      if (userStore.user?.theme !== nextTheme) {
        userStore.patch({ theme: nextTheme });
      }
      theme.value = nextTheme;
      localStorage.setItem('theme', nextTheme);

      useCookie('theme', { maxAge: oneYear, path: '/', sameSite: 'lax' }).value = nextTheme;
    }
  }

  watch(
    () => userStore.user,
    (user) => set(user?.theme),
  );

  return reactive({
    theme: readonly(theme),
    set,
  });
}
