import axios, { type AxiosInstance, type InternalAxiosRequestConfig } from 'axios';
import { isString } from '@borg/utils';

function httpMethodsFactory(request: AxiosInstance['request']) {
  const get: AxiosInstance['get'] = (url, config) => request({ url, method: 'get', ...config });
  const del: AxiosInstance['delete'] = (url, config) =>
    request({ url, method: 'delete', ...config });
  const post: AxiosInstance['post'] = (url, data, config) =>
    request({ url, method: 'post', data, ...config });
  const put: AxiosInstance['put'] = (url, data, config) =>
    request({ url, method: 'put', data, ...config });
  const patch: AxiosInstance['patch'] = (url, data, config) =>
    request({ url, method: 'patch', data, ...config });

  return {
    request,
    get,
    put,
    post,
    patch,
    delete: del,
  };
}

function getHeader(headers: InternalAxiosRequestConfig['headers'], headerName: string) {
  return headers && typeof headers.get === 'function' && headers.get(headerName);
}

function debugRequestInterceptor(config: InternalAxiosRequestConfig) {
  const log = [config.method?.toUpperCase(), `${config.baseURL}${config.url}`];

  const authHeader = getHeader(config.headers, 'Authorization');
  if (isString(authHeader)) {
    const briefAuthHeader = authHeader
      .split(' ')
      .reduce<string[]>((acc, part) => {
        if (part.length >= 20)
          acc.push(`${part.substring(0, 6)}...${part.substring(part.length - 6)}`);
        else acc.push(part);
        return acc;
      }, [])
      .join(' ');

    log.push(`[${briefAuthHeader}]`);
  } else {
    log.push('[NoAuthHeader]');
  }

  console.log(...log);

  return config;
}

/**
 * Creates HTTP client which makes calls towards the proxy.
 * NOTE: This gets called by both, client and the server.
 */
function createProxyHttp() {
  return httpMethodsFactory((requestConfig) => {
    const requestHeaders = useRequestHeaders();
    const headers: Record<string, string> = {};
    let rootBaseUrl = '';

    if (process.server) {
      /**
       * On initial non-token arrival, server plugin gets a new token and sets it in a cookie
       * That cookie value is not visible on subsequent server requests on that arrival instance
       * So 'state' (useToken) is used to make token data visible
       */
      const tokenCookie = useCookie('token').value;
      const tokenState = useToken().value;
      const token = tokenCookie ?? tokenState;
      headers.Cookie = `token=${token}`;
      rootBaseUrl = `http://localhost:3000`;
    }

    headers['x-app'] = useProjectConfig().domain;
    headers['Accept-Language'] = useNuxtApp().$i18n.locale.value;
    headers['User-Agent'] = requestHeaders['user-agent'];

    const nextConfig = { ...requestConfig, headers };

    const http = axios.create({
      baseURL: `${rootBaseUrl}/api/proxy`,
      validateStatus: (status) => status >= 200 && status < 500,
    });

    return http.request(nextConfig);
  });
}

/**
 * Crates HTTP client for API.
 * NOTE: This gets only called by the server.
 */
export function createApiHttp(baseURL?: string) {
  const apiHttp = axios.create({
    baseURL,
    validateStatus: (status) => status >= 200 && status < 500,
  });

  apiHttp.interceptors.request.use(debugRequestInterceptor);

  return httpMethodsFactory((config) => {
    return apiHttp.request(config);
  });
}

export const http = createProxyHttp();
