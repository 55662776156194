<script setup lang="ts">
  import { MpText } from '@borg/ui';

  withDefaults(
    defineProps<{
      text: string;
      centered?: boolean;
    }>(),
    {
      centered: true,
    },
  );

  const project = useProjectConfig();
</script>

<template>
  <div
    :class="{
      'disclaimer': true,
      'disclaimer--centered': centered,
    }"
  >
    <MpText no-margin>
      {{ text }}
    </MpText>
    <i18n-t
      keypath="SIGN_UP_PAGE.DISCLAIMER.TEXT"
      scope="global"
    >
      <template #tos>
        <LinksLocale
          :to="{ name: 'info-slug', params: { slug: $t('BRAND_LINKS.TOS.SLUG') } }"
          target="_blank"
        >
          <MpText
            semibold
            link
          >
            {{ $t('SIGN_UP_PAGE.DISCLAIMER.TOS') }}
          </MpText>
        </LinksLocale>
      </template>
      <template #privacy>
        <LinksLocale
          :to="{ name: 'info-slug', params: { slug: $t('BRAND_LINKS.PRIVACY.SLUG') } }"
          target="_blank"
        >
          <MpText
            semibold
            link
          >
            {{ $t('SIGN_UP_PAGE.DISCLAIMER.PRIVACY') }}
          </MpText>
        </LinksLocale>
      </template>
    </i18n-t>
    <MpText no-margin> {{ project.title }}. </MpText>
  </div>
</template>

<style scoped lang="scss">
  .disclaimer {
    margin: var(--mp-space-30) 0;
    display: inline-flex;
    gap: var(--mp-space-20);
    flex-wrap: wrap;

    &--centered {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
</style>
