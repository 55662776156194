<script setup lang="ts">
  useHead({
    script: [
      {
        src: 'https://connect.facebook.net/en_GB/sdk.js',
        crossorigin: 'anonymous',
        async: true,
        defer: true,
      },
    ],
  });
</script>

<template>
  <span v-if="false" />
</template>
