import {
  JobApplication,
  JobDetails,
  JobDetailsSource,
  JobFilter,
  JobGroup,
  JobPreview,
  JobSearchParams,
  JobSearchResult,
  Paginated,
  UserJobApplyFull,
  UserJobApplyGuest,
} from '@borg/types';

class JobsService {
  getFeaturedJobs = async (params: { page: number }) => {
    const response = await http.get<Paginated<JobGroup>>(`/jobs/featured`, { params });
    return response.data;
  };
  async get(jobId: string) {
    const response = await http.get<JobDetails>(`/jobs/${jobId}`);
    return response.data;
  }
  apply = (data: UserJobApplyFull) =>
    serviceHttpPostFactory<JobApplication>(`/applications/${data.id}/application`, data);
  applyGuest = (data: UserJobApplyGuest) =>
    serviceHttpPostFactory<JobApplication>(`/applications/${data.id}/application`, data);
  search = (
    params: JobSearchParams & {
      excludeLocations: boolean;
    },
  ) => serviceHttpGetFactory<JobSearchResult>('/jobs/search', params);
  searchFeatured = (params: JobSearchParams) =>
    serviceHttpGetFactory<JobGroup[]>('/jobs/search/featured', params);
  resolveParams = (params: JobSearchParams) =>
    serviceHttpGetFactory<JobFilter>('/jobs/search/params', params);
  trackPageVisit = (jobId: JobPreview['id'], source?: JobDetailsSource) => {
    const qp = objectToQueryString({ source: source });
    return serviceHttpPostFactory<void>(`/track-page-visit?${qp}`, {
      jobId,
    });
  };
}

export const jobsService = new JobsService();
