import revive_payload_client_P4tOF3KYK0 from "/app/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@unocss+reset@0.60.2_eslint@8.54.0_floating-vue@5.2.2_ro_bouldzzfoti3i7dgyvmeabpoae/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_OdhPKkMeF3 from "/app/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@unocss+reset@0.60.2_eslint@8.54.0_floating-vue@5.2.2_ro_bouldzzfoti3i7dgyvmeabpoae/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_w9FlKvITQb from "/app/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@unocss+reset@0.60.2_eslint@8.54.0_floating-vue@5.2.2_ro_bouldzzfoti3i7dgyvmeabpoae/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_0BCkSs3Sfn from "/app/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@unocss+reset@0.60.2_eslint@8.54.0_floating-vue@5.2.2_ro_bouldzzfoti3i7dgyvmeabpoae/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_dA34VRtlGH from "/app/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@unocss+reset@0.60.2_eslint@8.54.0_floating-vue@5.2.2_ro_bouldzzfoti3i7dgyvmeabpoae/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_58t8j8OANp from "/app/node_modules/.pnpm/@pinia+nuxt@0.4.11_rollup@4.17.2_vue@3.4.27/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/apps/site/.nuxt/components.plugin.mjs";
import prefetch_client_XFWfoFpnV9 from "/app/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@unocss+reset@0.60.2_eslint@8.54.0_floating-vue@5.2.2_ro_bouldzzfoti3i7dgyvmeabpoae/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_rVZgce9kWZ from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.2.0_rollup@4.17.2_vue@3.4.27/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_dUuUVlrcsl from "/app/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@unocss+reset@0.60.2_eslint@8.54.0_floating-vue@5.2.2_ro_bouldzzfoti3i7dgyvmeabpoae/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _20_env_612nqPD8GK from "/app/apps/site/plugins/20.env.ts";
import _30_user_jwt_mvBwInWRkI from "/app/apps/site/plugins/30.user-jwt.ts";
import _40_sentry_client_HjOVFNcJVj from "/app/apps/site/plugins/40.sentry.client.ts";
import _50_masonry_wall_FORjha14i9 from "/app/apps/site/plugins/50.masonry-wall.ts";
import _70_google_tag_manger_client_Xc6xP22Ep5 from "/app/apps/site/plugins/70.google-tag-manger.client.ts";
import _80_meta_client_KYedthzPAW from "/app/apps/site/plugins/80.meta.client.ts";
import error_handler_kEP5FliEXj from "/app/apps/site/plugins/error-handler.ts";
export default [
  revive_payload_client_P4tOF3KYK0,
  unhead_OdhPKkMeF3,
  router_w9FlKvITQb,
  payload_client_0BCkSs3Sfn,
  check_outdated_build_client_dA34VRtlGH,
  plugin_vue3_58t8j8OANp,
  components_plugin_KR1HBZs4kY,
  prefetch_client_XFWfoFpnV9,
  i18n_rVZgce9kWZ,
  chunk_reload_client_dUuUVlrcsl,
  _20_env_612nqPD8GK,
  _30_user_jwt_mvBwInWRkI,
  _40_sentry_client_HjOVFNcJVj,
  _50_masonry_wall_FORjha14i9,
  _70_google_tag_manger_client_Xc6xP22Ep5,
  _80_meta_client_KYedthzPAW,
  error_handler_kEP5FliEXj
]