import { isError } from '@borg/utils';

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.config.errorHandler = (error) => {
    if (isError(error)) {
      console.error(error);
      showError({ fatal: true, statusCode: 500, message: error.message, stack: error.stack });
    }
  };
});
