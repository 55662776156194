<script setup lang="ts">
  import { z } from 'zod';
  import { MpButton, MpDivider, MpInput, MpText, pushToastMessage } from '@borg/ui';
  import { injectAuthForm } from '../useAuthForm';

  const emit = defineEmits<{
    (event: 'success'): void;
  }>();

  const { t } = useI18n();
  const { $gtag } = useNuxtApp();
  const userStore = useUserStore();
  const authForm = injectAuthForm();

  const { state, values, validate } = useForm({
    initialData: () => ({
      verificationCode: '',
    }),
    schema: z.object({
      verificationCode: z.string().min(1, t('REQUIRED_INPUT')),
    }),
  });

  async function onResendVerificationLink() {
    await userStore.resendVerificationLink(authForm.data.email);
    if (userStore.resendVerificationLinkState === 'isSuccess') {
      pushToastMessage({
        type: 'success',
        title: t('SIGN_UP_PAGE.RESEND_VERIFICATION_CODE_SUCCESS_TOAST.TITLE'),
        subtitle: t('SIGN_UP_PAGE.RESEND_VERIFICATION_CODE_SUCCESS_TOAST.SUBTITLE'),
      });
    } else {
      pushToastMessage({
        type: 'alert',
        title: t('SIGN_UP_PAGE.RESEND_VERIFICATION_CODE_ERROR_TOAST.TITLE'),
        subtitle: t('SIGN_UP_PAGE.RESEND_VERIFICATION_CODE_ERROR_TOAST.SUBTITLE'),
      });
    }
  }

  async function onSubmit() {
    const valid = await validate();
    if (valid) {
      await userStore.verify({
        email: authForm.data.email,
        verificationCode: values.verificationCode,
      });
      if (userStore.isAuthenticated) {
        $gtag?.logUser('auth:register');
        emit('success');
      } else {
        pushToastMessage({
          type: 'alert',
          title: t('SIGN_UP_PAGE.VERIFY_ERROR_TOAST.TITLE'),
          subtitle: t('SIGN_UP_PAGE.VERIFY_ERROR_TOAST.SUBTITLE'),
        });
      }
    }
  }
</script>

<template>
  <div class="verify-page">
    <MpText
      as="h3"
      no-margin
    >
      {{ `${$t('SIGN_UP_PAGE.REGISTER_SUCCESS_TITLE')} ${authForm.data.firstName}!` }}
    </MpText>
    <div class="verify-page__feedback">
      <MpText no-margin>
        {{ $t('SIGN_UP_PAGE.REGISTER_SUCCESS_VERIFICATION_CODE_SENT_DESCRIPTION') }}
      </MpText>
      <MpText
        bold
        no-margin
        class="verify-page__feedback-email"
      >
        {{ authForm.data.email }}
      </MpText>
    </div>
    <form
      class="verify-page__form"
      @submit.prevent="onSubmit"
    >
      <MpInput
        v-model="values.verificationCode"
        test="verify-form-code-input"
        size="lg"
        :label="$t('SIGN_UP_PAGE.REGISTER_SUCCESS_VERIFICATION_CODE_LABEL')"
        :error="state.verificationCode.message"
      />
      <MpButton
        :text="$t('SIGN_UP_PAGE.REGISTER_SUCCESS_CONFIRM_BUTTON_LABEL')"
        size="lg"
        type="submit"
        test="verify-form-submit-button"
        :is-loading="userStore.verifyState === 'inProgress'"
        :disabled="userStore.verifyState === 'inProgress'"
        is-full-width
      />
    </form>
    <MpDivider margin="lg" />
    <MpText
      class="verify-page__resend"
      no-margin
    >
      {{ $t('SIGN_UP_PAGE.REGISTER_SUCCESS_DESCRIPTION.1') }}
      <MpText
        class="verify-page__link"
        link
        no-margin
        :link-disabled="userStore.resendVerificationLinkState === 'inProgress'"
        @click="onResendVerificationLink"
      >
        {{ $t('SIGN_UP_PAGE.REGISTER_SUCCESS_DESCRIPTION.2') }}
      </MpText>
    </MpText>
  </div>
</template>

<style scoped lang="scss">
  .verify-page {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &__feedback {
      margin: var(--mp-space-30) 0 var(--mp-space-50);
    }

    &__feedback-email {
      margin-left: var(--mp-space-20);
    }

    &__form {
      width: 100%;

      > button {
        margin-top: var(--mp-space-50);
      }
    }

    &__link {
      font-weight: var(--mp-font-weight-semibold);
    }

    &__resend {
      padding: 0 var(--mp-space-100);
      text-align: center;
    }
  }
</style>
