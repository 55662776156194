import { isArray, isBoolean, isNumber, isString, objectKeys } from '@borg/utils';

export function objectToQueryString(object: Record<string, unknown>): string {
  return objectKeys(object)
    .reduce<string[]>((acc, key) => {
      if (key) {
        const value = object[key];
        if (isNumber(value) || (isString(value) && value.length > 0)) {
          acc.push(`${key}=${encodeQueryValue(value)}`);
        }
        if (isArray(value)) {
          value.forEach((val) => {
            if (isNumber(val) || (isString(val) && val.length > 0)) {
              acc.push(`${key}=${encodeQueryValue(val)}`);
            }
          });
        }
        if (isBoolean(value) && value) {
          acc.push(`${key}=${+value}`);
        }
      }
      return acc;
    }, [])
    .join('&');
}

function encodeQueryValue(value: number | string): string {
  return encodeURI(typeof value === 'number' ? value.toString(10) : value);
}
