<script setup lang="ts">
  import { z } from 'zod';
  import { MpButton, MpLoadingContainer, MpPassword, MpText, pushToastMessage } from '@borg/ui';
  import { MIN_PASSWORD_LENGTH, passwordValidator } from '@borg/validation';

  const { t } = useI18n();
  const userStore = useUserStore();
  const hash = useRoute().query.hash?.toString() ?? '';

  const hashHasError = computed(
    () =>
      userStore.verifyForgottenPasswordHashState === 'isInvalid' ||
      userStore.verifyForgottenPasswordHashState === 'hasError',
  );
  const isSubmitButtonDisabled = computed(
    () =>
      userStore.setForgottenPasswordState === 'inProgress' ||
      userStore.setForgottenPasswordState === 'isSuccess',
  );

  const { state, values, validate } = useForm({
    initialData: () => ({
      password: '',
      repeatPassword: '',
    }),
    schema: z
      .object({
        password: passwordValidator,
        repeatPassword: z.string(),
      })
      .superRefine((val, ctx) => {
        if (val.password && val.password !== val.repeatPassword) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t('SIGN_UP_PAGE.NEW_PASSWORD_MISMATCH_ERROR'),
            path: ['repeatPassword'],
          });
        }
      }),
  });

  async function onSubmit() {
    const valid = await validate();
    if (valid) {
      await userStore.setForgottenPassword({ hash, password: values.password });
      if (userStore.setForgottenPasswordState === 'isSuccess') {
        pushToastMessage({
          type: 'success',
          subtitle: t('SIGN_UP_PAGE.NEW_PASSWORD_SUCCESS_TOAST.SUBTITLE'),
        });
        window.location.href = useDomain().value;
      } else {
        pushToastMessage({
          type: 'alert',
          title: t('SIGN_UP_PAGE.NEW_PASSWORD_ERROR_TOAST.TITLE'),
          subtitle: t('SIGN_UP_PAGE.NEW_PASSWORD_ERROR_TOAST.SUBTITLE'),
        });
      }
    }
  }
</script>

<template>
  <form @submit.prevent="onSubmit">
    <MpLoadingContainer :is-loading="userStore.verifyForgottenPasswordHashState === 'inProgress'">
      <div class="form">
        <template v-if="userStore.verifyForgottenPasswordHashState === 'isSuccess'">
          <MpText
            as="h2"
            no-margin
          >
            {{ $t('SIGN_UP_PAGE.NEW_PASSWORD_TITLE') }}
          </MpText>
          <MpText
            as="h5"
            regular
            style="margin-bottom: var(--mp-space-30)"
          >
            {{ $t('SIGN_UP_PAGE.NEW_PASSWORD_DESCRIPTION', { n: MIN_PASSWORD_LENGTH }) }}
          </MpText>
          <MpPassword
            v-model="values.password"
            test="new-password"
            :label="$t('SIGN_UP_PAGE.NEW_PASSWORD_PASSWORD_LABEL')"
            :error="
              state.password.message
                ? t(state.password.message, { n: MIN_PASSWORD_LENGTH })
                : undefined
            "
            autocomplete="new-password"
          />
          <MpPassword
            v-model="values.repeatPassword"
            test="confirm-password"
            :label="$t('SIGN_UP_PAGE.NEW_PASSWORD_RETYPE_PASSWORD_LABEL')"
            :error="state.repeatPassword.message"
            autocomplete="new-password"
          />
          <MpButton
            class="form__button"
            type="submit"
            test="submit-password"
            :text="$t('SIGN_UP_PAGE.NEW_PASSWORD_CONFIRM_BUTTON_LABEL')"
            :is-loading="userStore.setForgottenPasswordState === 'inProgress'"
            :disabled="isSubmitButtonDisabled"
            is-full-width
          />
        </template>
        <template v-else-if="hashHasError">
          {{ $t('SIGN_UP_PAGE.NEW_PASSWORD_WRONG_LINK_LABEL') }}
        </template>
      </div>
    </MpLoadingContainer>
  </form>
</template>

<style scoped lang="scss">
  .form {
    display: flex;
    flex-direction: column;
    gap: var(--mp-space-50);

    &__disclaimer {
      padding: 0 20%;
      text-align: center;
    }

    &__button {
      margin-top: var(--mp-space-60);
    }
  }
</style>
