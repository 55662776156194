import * as Sentry from '@sentry/vue';
import { logger } from '@borg/core/utils/logger';

let hasReplayInit = false;

function initReplay() {
  if (hasReplayInit) {
    return;
  }

  const client = Sentry.getCurrentHub().getClient();
  const replay = new Sentry.Replay({
    networkDetailAllowUrls: [window.location.origin],
    maskAllText: false,
    blockAllMedia: false,
    maskAllInputs: false,
  });
  // only reason client doesn't exist is that `Sentry.init` was not called.
  if (client && client.addIntegration) {
    client.addIntegration(replay);
    hasReplayInit = true;
  }
}

export default defineNuxtPlugin(async (nuxtApp) => {
  const router = useRouter();
  const config = useRuntimeConfig();
  const { vueApp } = nuxtApp;
  const userStore = useUserStore();

  const client = Sentry.getCurrentHub().getClient();

  if (!client && config.public.sentryDsn) {
    Sentry.init({
      Vue: vueApp,
      enabled: true,
      dsn: config.public.sentryDsn,
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
      ],

      ignoreErrors: [
        /ResizeObserver/,
        /Network Error/,
        /Request aborted/,
        /AbortError: Share canceled/,
      ],
      tracesSampleRate: 0.0002, // Percentage of transactions sent to Sentry
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: 0.1, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      hooks: ['activate', 'update', 'mount'],
      trackComponents: true,
    });

    initReplay();
    if (userStore.isAuthenticated && userStore.user) {
      logger.setUser(userStore.user.id, userStore.user.email);
    }
    if (config.public.buildTime) {
      logger.setTag('buildTime', config.public.buildTime);
    }
  } else {
    logger.warn('Sentry DSN not found!');
  }

  return {
    provide: {
      logger,
    },
  };
});
