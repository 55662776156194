import type { ComputedRef, DeepReadonly, Ref, UnwrapNestedRefs } from 'vue';
import type { ZodObject, ZodType, ZodTypeAny } from 'zod';
import { ZodEffects } from 'zod';
import { deepEqual, isHasOwn, objectKeys } from '@borg/utils';

type FormData = Record<PropertyKey, any>;

interface UseFormReturn<FData> {
  values: UnwrapNestedRefs<FData>;
  state: DeepReadonly<UnwrapNestedRefs<FormState<FData>>>;
  hasErrors: DeepReadonly<Ref<boolean>>;
  isSubmitting: DeepReadonly<Ref<boolean>>;
  validate(validateAllFields?: boolean): Promise<boolean>;
  resetStates(): void;
  setValues(item: Partial<UnwrapNestedRefs<FData>>): void;
  handleSubmit(callback: () => Promise<void> | void): void;
}

type FormState<FData> = {
  [Key in keyof FData]: {
    message: string | undefined;
    hasError: boolean;
    isDirty: ComputedRef<boolean>;
    isTouched: boolean;
  };
};

type SchemaObject<FData> = ZodObject<{ [K in keyof FData]: ZodType<FData[K]> }>;

export function useForm<FData extends FormData>(options: {
  initialData?(): FData;
  schema: SchemaObject<FData> | ZodEffects<SchemaObject<FData>> | ZodTypeAny;
  validateOnChange?: boolean;
  immediate?: boolean;
}): UseFormReturn<FData> {
  const initialData = options.initialData || (() => ({}) as FormData);
  const values = reactive<FData>(initialData());
  const validateOnChange = options.validateOnChange ?? true;
  const immediate = options.immediate ?? true;
  const stateObj = {} as FormState<FormData>;
  const hasErrors = ref(false);

  // Initialized State Object
  for (const key in values) {
    const v = initialData()[key];

    stateObj[key] = {
      message: undefined,
      hasError: false,
      isDirty: computed(() => !deepEqual(v, values[key])),
      isTouched: false,
    };
  }

  // Make State Object reactive
  const state = reactive(stateObj);

  async function validate(formData: FData, validateAllFields = false) {
    // Validate Schema
    const result = await options.schema.safeParseAsync(formData);

    // Reset all fields in state
    for (const key in state) {
      if (isHasOwn(state, key)) {
        state[key].message = undefined;
        state[key].hasError = false;

        if (validateAllFields) {
          state[key].isTouched = true;
        } else if (state[key].isDirty) {
          state[key].isTouched = true;
        }
      }
    }

    // Set error fields
    if (!result.success) {
      for (const issue of result.error.issues) {
        for (const key of issue.path) {
          if (state[key] && (state[key].isDirty || state[key].isTouched)) {
            state[key].message = issue.message;
            state[key].hasError = true;
          }
        }
      }
    }

    return result.success;
  }

  function resetStates() {
    const initial = initialData();
    objectKeys(initial).forEach((key) => {
      values[key as keyof FData] = initial[key];
    });

    // Reset certain state fields
    for (const key in state) {
      if (isHasOwn(state, key)) {
        state[key].message = undefined;
        state[key].hasError = false;
        state[key].isTouched = false;
      }
    }
  }

  function setValues(item: Partial<UnwrapNestedRefs<FData>>) {
    objectKeys(item).forEach((key) => {
      values[key] = item[key] as UnwrapNestedRefs<FData>[keyof UnwrapNestedRefs<FData>];
    });
  }

  async function onValuesChange(nextValues: typeof values) {
    if (validateOnChange) {
      hasErrors.value = !(await validate(nextValues));
    }
  }

  watch(values, onValuesChange, { immediate });

  const isSubmitting = ref(false);

  return {
    values,
    state: readonly(state),
    hasErrors: readonly(hasErrors),
    isSubmitting: readonly(isSubmitting),
    validate: (validateAllFields = true) => validate(values, validateAllFields),
    resetStates,
    setValues,
  };
}
