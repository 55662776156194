import { AxiosRequestConfig } from 'axios';
import { ServiceResult } from '@borg/types';

export async function serviceHttpGetFactory<T>(
  url: string,
  queryObject?: Record<string, unknown>,
): ServiceResult<T> {
  let _url = url;
  if (queryObject && Object.keys(queryObject).length > 0) {
    _url += `?${objectToQueryString(queryObject)}`;
  }

  const result = await http.get(_url);

  if (result.status >= 200 && result.status < 300) {
    return { isSuccess: true, data: result.data };
  }

  return { isSuccess: false, message: `Could not 'GET' ${_url}` };
}

export async function serviceHttpDeleteFactory<T = never>(url: string): ServiceResult<T> {
  const result = await http.delete(url);

  if (result.status >= 200 && result.status < 300) {
    return { isSuccess: true, data: result.data };
  }

  return { isSuccess: false, message: `Could not 'DELETE' ${url}` };
}

export async function serviceHttpPatchFactory<T>(url: string, data: unknown): ServiceResult<T> {
  const result = await http.patch(url, data);

  if (result.status >= 200 && result.status < 300) {
    return { isSuccess: true, data: result.data };
  }

  return { isSuccess: false, message: `Could not 'PATCH' ${url}` };
}

export async function serviceHttpPostFactory<T, D = unknown>(
  url: string,
  data?: D,
  config?: AxiosRequestConfig,
): ServiceResult<T> {
  const result = await http.post(url, data, config);

  if (result.status >= 200 && result.status < 300) {
    return { isSuccess: true, data: result.data };
  }

  return { isSuccess: false, message: `Could not 'POST' ${url}` };
}

export async function serviceHttpPutFactory<T, D = unknown>(
  url: string,
  data?: D,
): ServiceResult<T> {
  const result = await http.put(url, data);

  if (result.status >= 200 && result.status < 300) {
    return { isSuccess: true, data: result.data };
  }

  return { isSuccess: false, message: `Could not 'PUT' ${url}` };
}
