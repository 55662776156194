import { decodeJwtToken } from '@borg/core/utils/jwt';

export default defineNuxtPlugin(async () => {
  const oneYear = 60 * 60 * 24 * 365;
  const token = useCookie('token', {
    path: '/',
    httpOnly: true,
    maxAge: oneYear,
    sameSite: 'strict',
  });
  if (token.value) {
    const tokenDecodedValue = decodeJwtToken(token.value);
    if ('id' in tokenDecodedValue) {
      const userStore = useUserStore();
      await userStore.init(tokenDecodedValue);
    }
  } else {
    if (process.server) {
      const result = await authService.getAnonToken();
      if (result.isSuccess) {
        token.value = result.data.accessToken;
        useToken(result.data.accessToken);
      }
    }
  }
});
