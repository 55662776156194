const AFFECTED_ROUTES = {
    '/info/uvjeti-koristenja': '/en/info/terms-of-use',
    '/en/info/terms-of-use': '/info/uvjeti-koristenja',
    '/info/pravila-privatnosti': '/en/info/privacy-policy',
    '/en/info/privacy-policy': '/info/pravila-privatnosti',
    '/info/kontakt': '/en/info/contact',
    '/en/info/contact': '/info/kontakt',
}

const INVALID_ROUTES = ['/en/info/uvjeti-koristenja', '/en/info/pravila-privatnosti', '/en/info/kontakt', '/info/constanct', '/info/privacy-policy', '/info/terms-of-use']


// this makes language switching on 'pages/info/[slug] work correctly, we can't do it using 'locale/pages.ts' since we don't have the names of the pages in advance, all is handled by the info/[slug].vue'
export default defineNuxtRouteMiddleware(async (to, from) => {
    const RELEVANT_REDIRECT = AFFECTED_ROUTES[from.path as keyof typeof AFFECTED_ROUTES];

    if (RELEVANT_REDIRECT && to.path !== from.path && to.path !== RELEVANT_REDIRECT && INVALID_ROUTES.includes(to.path)) {
        return navigateTo(RELEVANT_REDIRECT)
    }
});
