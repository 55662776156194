export default defineNuxtPlugin(async () => {
  const config = useRuntimeConfig();

  const isProd = config.public.appEnv === 'production';
  const isTest = config.public.appEnv === 'test';
  const isDev = config.public.appEnv === 'develop';

  return {
    provide: {
      isProd,
      isDev,
      isTest,
    },
  };
});
