import { z } from 'zod';
import { objectKeys } from '@borg/utils';

export default defineNuxtPlugin((app) => {
  const metaObjectSchema = z.object({
    utm_campaign: z.string().optional(),
    utm_source: z.string().optional(),
    utm_medium: z.string().optional(),
    utm_term: z.string().optional(),
    utm_content: z.string().optional(),
  });

  const metaParseResult = metaObjectSchema.safeParse(app._route.query);

  if (metaParseResult.success && metaParseResult.data && objectKeys(metaParseResult.data).length) {
    authService.sendMeta(metaParseResult.data);
  }
});
