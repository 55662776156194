import jwt_decode from 'jwt-decode';
import { JwtPayload, JwtPayloadAnonUser } from '@borg/types';

export function decodeJwt(token: string): JwtPayload {
  return jwt_decode<JwtPayload>(token);
}

export function decodeJwtAnon(token: string): JwtPayloadAnonUser {
  return jwt_decode<JwtPayloadAnonUser>(token);
}

export function decodeJwtToken(token: string) {
  return jwt_decode<JwtPayload | JwtPayloadAnonUser>(token);
}
