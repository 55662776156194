import { defineStore } from 'pinia';
import type {
  AsyncState,
  ChangeEmail,
  ChangePassword,
  JwtPayload,
  JwtPayloadAnonUser,
  Login,
  Register,
  User,
  Verify,
} from '@borg/types';
import { AuthFailCode } from '@/types';

export const useUserStore = defineStore('user-store', () => {
  const initState = ref<AsyncState>('isInitial');
  const patchState = ref<AsyncState>('isInitial');
  const loginState = ref<AsyncState>('isInitial');
  const registerState = ref<AsyncState>('isInitial');
  const verifyState = ref<AsyncState>('isInitial');
  const verifyNewEmailState = ref<AsyncState>('isInitial');
  const requestForgottenPasswordState = ref<AsyncState>('isInitial');
  const verifyForgottenPasswordHashState = ref<AsyncState>('isInitial');
  const setForgottenPasswordState = ref<AsyncState>('isInitial');
  const resendVerificationLinkState = ref<AsyncState>('isInitial');
  const changeEmailState = ref<AsyncState>('isInitial');
  const changePasswordState = ref<AsyncState>('isInitial');
  const user = ref<User | null>(null);
  const originalToken = ref<string>();
  const authError = ref<AuthFailCode>();
  const isAuthenticated = computed(() => !!user.value);

  async function init(data?: JwtPayload | JwtPayloadAnonUser) {
    try {
      initState.value = 'inProgress';

      if (data && 'originalToken' in data && data.originalToken) {
        originalToken.value = data.originalToken;
      } else {
        originalToken.value = undefined;
      }

      const result = await userService.getSelf();
      if (result.isSuccess) {
        initState.value = 'isSuccess';
        user.value = result.data;
        logger.setUser(user.value.id, user.value.email);
      } else {
        initState.value = 'isInvalid';
      }
    } catch (error) {
      initState.value = 'hasError';
      logger.error(`Error initialising user: ${error}`);
    }
  }

  async function patch(data: Partial<User>) {
    try {
      patchState.value = 'inProgress';
      const result = await userService.patchSelf(data);
      if (result.isSuccess) {
        patchState.value = 'isSuccess';
        user.value = result.data;
      } else {
        patchState.value = 'isInvalid';
      }
    } catch (error) {
      patchState.value = 'hasError';
    }
  }

  async function login(data: Login) {
    try {
      loginState.value = 'inProgress';
      authError.value = undefined;
      const result = await authService.login(data);
      if (result.isSuccess) {
        loginState.value = 'isSuccess';
        await init();
        logger.info('User login');
      } else {
        authError.value = result.code;
        loginState.value = 'isInvalid';
      }
    } catch (error) {
      authError.value = undefined;
      loginState.value = 'hasError';
    }
  }

  async function loginWithCode(code: string) {
    try {
      loginState.value = 'inProgress';
      const result = await authService.loginWithCode({ code });
      if (result.isSuccess) {
        loginState.value = 'isSuccess';
        await init(result.data);
        logger.info('User login with code');
      } else {
        loginState.value = 'isInvalid';
      }
    } catch (error) {
      loginState.value = 'hasError';
    }
  }

  async function loginWithOriginalToken() {
    if (originalToken.value) {
      const result = await authService.loginWithToken({ token: originalToken.value });
      if (result.isSuccess) {
        user.value = null;
      }
      await init();
    }
  }

  async function googleLogin(data: { accessToken: string }) {
    try {
      loginState.value = 'inProgress';
      authError.value = undefined;
      const result = await authService.googleLogin(data);
      if (result.isSuccess) {
        loginState.value = 'isSuccess';
        await init();
      } else {
        authError.value = result.code;
        loginState.value = 'isInvalid';
      }
    } catch (error) {
      logger.error(`User Google login error: ${error}`);
      authError.value = undefined;
      loginState.value = 'hasError';
    }
  }

  async function facebookLogin(data: { accessToken: string; userId: string }) {
    try {
      loginState.value = 'inProgress';
      authError.value = undefined;
      const result = await authService.facebookLogin(data);
      if (result.isSuccess) {
        loginState.value = 'isSuccess';
        await init();
      } else {
        authError.value = result.code;
        loginState.value = 'isInvalid';
      }
    } catch (error) {
      logger.error(`User Facebook login error: ${error}`);
      authError.value = undefined;
      loginState.value = 'hasError';
    }
  }

  async function logout() {
    const result = await authService.logout();
    if (result.isSuccess) {
      user.value = null;
      logger.unsetUser();
    }
  }

  async function register(data: Register) {
    try {
      registerState.value = 'inProgress';
      authError.value = undefined;
      const result = await authService.register(data);
      if (result.isSuccess) {
        registerState.value = 'isSuccess';
        logger.info('User registered');
      } else {
        authError.value = result.code;
        registerState.value = 'isInvalid';
      }
    } catch (error) {
      authError.value = undefined;
      registerState.value = 'hasError';
    }
  }

  async function verify(data: Verify) {
    try {
      verifyState.value = 'inProgress';
      const result = await authService.verify(data);
      if (result.isSuccess) {
        verifyState.value = 'isSuccess';
        await init();
        logger.info('User registration verified');
      } else {
        verifyState.value = 'isInvalid';
      }
    } catch (error) {
      verifyState.value = 'hasError';
    }
  }

  async function verifyEmail(data: Verify) {
    try {
      verifyNewEmailState.value = 'inProgress';
      const result = await authService.verifyEmail(data);
      if (result.isSuccess) {
        verifyNewEmailState.value = 'isSuccess';
        await init();
        logger.info('Email change verified');
      } else {
        verifyNewEmailState.value = 'isInvalid';
      }
    } catch (error) {
      verifyNewEmailState.value = 'hasError';
    }
  }

  async function requestForgottenPassword(data: { email: string }) {
    try {
      requestForgottenPasswordState.value = 'inProgress';
      const result = await authService.requestForgottenPassword(data);
      if (result.isSuccess) {
        requestForgottenPasswordState.value = 'isSuccess';
      } else {
        requestForgottenPasswordState.value = 'isInvalid';
      }
    } catch (error) {
      requestForgottenPasswordState.value = 'hasError';
    }
  }

  async function verifyForgottenPasswordHash(data: { hash: string }) {
    try {
      verifyForgottenPasswordHashState.value = 'inProgress';
      const result = await authService.verifyForgottenPasswordHash(data);
      if (result.isSuccess) {
        verifyForgottenPasswordHashState.value = 'isSuccess';
      } else {
        verifyForgottenPasswordHashState.value = 'isInvalid';
      }
    } catch (error) {
      verifyForgottenPasswordHashState.value = 'hasError';
    }
  }

  async function setForgottenPassword(data: { hash: string; password: string }) {
    try {
      setForgottenPasswordState.value = 'inProgress';
      const result = await authService.setForgottenPassword(data);
      if (result.isSuccess) {
        setForgottenPasswordState.value = 'isSuccess';
        await init();
      } else {
        setForgottenPasswordState.value = 'isInvalid';
      }
    } catch (error) {
      setForgottenPasswordState.value = 'hasError';
    }
  }

  async function resendVerificationLink(email: string) {
    try {
      resendVerificationLinkState.value = 'inProgress';
      const result = await userService.resendVerificationLink({ email });
      if (result.isSuccess) {
        resendVerificationLinkState.value = 'isSuccess';
      } else {
        resendVerificationLinkState.value = 'isInvalid';
      }
    } catch (error) {
      resendVerificationLinkState.value = 'hasError';
    }
  }

  async function changeEmail(data: ChangeEmail) {
    try {
      changeEmailState.value = 'inProgress';
      const result = await userService.changeEmail(data);
      if (result.isSuccess) {
        changeEmailState.value = 'isSuccess';
        await init();
      } else {
        changeEmailState.value = 'isInvalid';
      }
    } catch (error) {
      changeEmailState.value = 'hasError';
    }
  }

  async function changePassword(data: ChangePassword) {
    try {
      changePasswordState.value = 'inProgress';
      const result = await userService.changePassword(data);
      if (result.isSuccess) {
        changePasswordState.value = 'isSuccess';
      } else {
        changePasswordState.value = 'isInvalid';
      }
    } catch (error) {
      changePasswordState.value = 'hasError';
    }
  }

  return {
    initState,
    patchState,
    loginState,
    registerState,
    verifyState,
    verifyNewEmailState,
    requestForgottenPasswordState,
    verifyForgottenPasswordHashState,
    setForgottenPasswordState,
    resendVerificationLinkState,
    changeEmailState,
    changePasswordState,
    authError,
    isAuthenticated,
    user,
    originalToken,
    init,
    patch,
    login,
    loginWithCode,
    loginWithOriginalToken,
    googleLogin,
    facebookLogin,
    logout,
    register,
    verify,
    verifyEmail,
    requestForgottenPassword,
    verifyForgottenPasswordHash,
    setForgottenPassword,
    resendVerificationLink,
    changeEmail,
    changePassword,
  };
});
