<script setup lang="ts">
  import { MpUIProvider } from '@borg/ui';
  import { en, hr } from 'mp-poll';
  import { initGoogleOneTapLogin } from '@/utils/googleAccount';
  import { provideAuthForm } from '@/components/Auth/useAuthForm';
  import { openManageCookiesPolicyModal } from '@/components/ManageCookiesPolicyModal.vue';
  import { provideHistory } from '@/components/JobSearchBar/useHistory';

  provideAuthForm();
  provideHistory();

  const { $isProd } = useNuxtApp();
  const i18n = useI18n();
  const route = useRoute();
  const getRouteBaseName = useRouteBaseName();
  const domain = useDomain();
  const siteTheme = useSiteTheme();
  const config = useRuntimeConfig();
  const projectConfig = useProjectConfig();
  const lang = computed(() => i18n.locale.value);
  const thisUrl = computed(() => `${domain.value}${route.fullPath}`);
  const assetsBaseUrl = computed(() => config.public.assetsBaseUrl);
  const projectName = computed(() => config.public.project.name ?? 'generic');
  const lightStyles = computed(() => `${assetsBaseUrl.value}/brand/${projectName.value}/light.css`);
  const darkStyles = computed(() => `${assetsBaseUrl.value}/brand/${projectName.value}/dark.css`);
  const headMeta = computed(() => {
    if (!$isProd) {
      return [...projectConfig.headMeta, { name: 'robots', content: 'noindex' }];
    }
    return projectConfig.headMeta;
  });

  i18n.mergeLocaleMessage('hr', hr);
  i18n.mergeLocaleMessage('en', en);

  const ogImage = projectConfig.parameters.siteOgImage
    ? {
        ogImage: projectConfig.parameters.siteOgImage.url,
        ogImageWidth: projectConfig.parameters.siteOgImage.width,
        ogImageHeight: projectConfig.parameters.siteOgImage.height,
      }
    : undefined;

  const routeBaseName = computed(() => getRouteBaseName(route) ?? '');

  useHead({
    htmlAttrs: { lang, 'data-theme': () => siteTheme.theme, 'data-route': routeBaseName },
    meta: [
      ...headMeta.value,
      { name: 'viewport', content: 'width=device-width, initial-scale=1.0 user-scalable=0' },
    ],
    titleTemplate: (page) => (page ? `${projectConfig.title} - ${page}` : projectConfig.title),
  });

  useSeoMeta({
    ogUrl: thisUrl,
    ...ogImage,
  });

  onMounted(() => {
    initGoogleOneTapLogin();
    openManageCookiesPolicyModal();
  });
</script>

<template>
  <Head>
    <Link
      rel="icon"
      :href="`${projectConfig.parameters.favicon}`"
    />
    <Link
      rel="stylesheet"
      href="/site.css"
    />
    <Link
      rel="preload"
      as="style"
      href="https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&family=Source+Sans+Pro:wght@300;400;600;700&display=swap"
    />
    <Link
      rel="stylesheet"
      href="https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&family=Source+Sans+Pro:wght@300;400;600;700&display=swap"
    />
    <Link
      rel="stylesheet"
      :href="lightStyles"
    />
    <Link
      rel="stylesheet"
      :href="darkStyles"
    />
    <GoogleScript />
    <FacebookScript />
    <Style> html, body, body > :first-child { height: 100%; } </Style>
  </Head>
  <MpUIProvider :theme="siteTheme.theme">
    <slot />
  </MpUIProvider>
</template>
