<script setup lang="ts">
  import { MpActionsModal, MpText } from '@borg/ui';

  const projectConfig = useProjectConfig();
</script>

<script lang="ts">
  import { useAsyncModalState } from '@borg/ui';
  import { isString } from '@borg/utils';

  const modal = useAsyncModalState<boolean>();

  export async function openManageCookiesPolicyModal() {
    const route = useRoute();

    if (
      !localStorage ||
      (isString(route.name) && route.name.match('my-profile-preview')) ||
      localStorage.getItem('cookies-policy-accepted')
    ) {
      return;
    }

    await modal.open();
    localStorage.setItem('cookies-policy-accepted', 'true');
  }
</script>

<template>
  <MpActionsModal
    :is-open="modal.isOpen"
    buttons-align="center"
    :confirm-button-label="$t('MANAGE_COOKIES_POLICY_MODAL.CONFIRM_BUTTON_LABEL')"
    hide-cancel-button
    close-button
    @confirm="modal.close(true)"
    @close="modal.close(false)"
  >
    <MpText>
      {{ $t('MANAGE_COOKIES_POLICY_MODAL.TEXT.1', { project: projectConfig.title }) }}
      <MpText
        bold
        link
      >
        <LinksLocale
          :to="{ name: 'info-slug', params: { slug: $t('MANAGE_COOKIES_POLICY_MODAL.SLUG') } }"
          target="_blank"
        >
          {{ $t('MANAGE_COOKIES_POLICY_MODAL.TEXT.2') }}
        </LinksLocale>
      </MpText>
    </MpText>
  </MpActionsModal>
</template>
