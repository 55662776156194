<script setup lang="ts">
  import { z } from 'zod';
  import {
    MpButton,
    MpCheckbox,
    MpField,
    MpInput,
    MpPassword,
    MpSelect,
    pushToastMessage,
  } from '@borg/ui';
  import { MIN_PASSWORD_LENGTH, RegisterUserSchema } from '@borg/validation';
  import { injectAuthForm } from '../useAuthForm';

  defineProps<{
    extendedRegistration?: boolean;
  }>();

  const { t } = useI18n();
  const userStore = useUserStore();

  const { data: educationLevels } = await educationLevelsRepository.getMany();

  const authForm = injectAuthForm();

  const { state, values, validate } = useForm({
    initialData: () => ({
      firstName: authForm.data.firstName,
      lastName: authForm.data.lastName,
      email: authForm.data.email,
      password: authForm.data.password,
      educationLevel: authForm.data.educationLevel,
      address: authForm.data.address,
      acceptTos: false,
    }),
    schema: z
      .intersection(RegisterUserSchema, z.object({ acceptTos: z.boolean() }))
      .superRefine((data, ctx) => {
        if (!data.acceptTos) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t('SIGN_UP_PAGE.DISCLAIMER.REQUIRED_ERROR'),
            path: ['acceptTos'],
          });
        }
      }),
  });

  async function onSubmit() {
    const valid = await validate();
    if (valid) {
      await userStore.register({ ...values });
      if (userStore.registerState === 'isSuccess') {
        authForm.setTab('verify');
      } else {
        if (userStore.authError === 'USER_EXISTS') {
          pushToastMessage({
            type: 'alert',
            title: t('SIGN_UP_PAGE.REGISTER_USER_EXISTS_ERROR_TOAST.TITLE'),
            subtitle: t('SIGN_UP_PAGE.REGISTER_USER_EXISTS_ERROR_TOAST.SUBTITLE'),
          });
        } else {
          pushToastMessage({
            type: 'alert',
            title: t('SIGN_UP_PAGE.REGISTER_ERROR_TOAST.TITLE'),
            subtitle: t('SIGN_UP_PAGE.REGISTER_ERROR_TOAST.SUBTITLE'),
          });
        }
      }
    }
  }

  const update = useDebounceFn((data: typeof values) => {
    authForm.setData(data);
  }, 500);

  watch(values, update);
</script>

<template>
  <form
    class="form"
    @submit.prevent="onSubmit"
  >
    <MpInput
      v-model="values.firstName"
      required
      :label="$t('SIGN_UP_PAGE.REGISTER_FIRST_NAME_LABEL')"
      :placeholder="$t('SIGN_UP_PAGE.REGISTER_FIRST_NAME_PLACEHOLDER')"
      :error="state.firstName.message ? t(state.firstName.message) : undefined"
      size="lg"
      autocomplete="given-name"
      test="register-form-first-name-input"
    />
    <MpInput
      v-model="values.lastName"
      required
      :label="$t('SIGN_UP_PAGE.REGISTER_LAST_NAME_LABEL')"
      :placeholder="$t('SIGN_UP_PAGE.REGISTER_LAST_NAME_PLACEHOLDER')"
      :error="state.lastName.message ? t(state.lastName.message) : undefined"
      size="lg"
      autocomplete="family-name"
      test="register-form-last-name-input"
    />
    <MpInput
      v-model="values.email"
      required
      :label="$t('SIGN_UP_PAGE.REGISTER_EMAIL_LABEL')"
      :placeholder="$t('SIGN_UP_PAGE.REGISTER_EMAIL_PLACEHOLDER')"
      :error="state.email.message ? t(state.email.message) : undefined"
      size="lg"
      autocomplete="username"
      test="register-form-email-input"
    />
    <MpPassword
      v-model="values.password"
      required
      :label="$t('SIGN_UP_PAGE.REGISTER_PASSWORD_LABEL')"
      :placeholder="$t('SIGN_UP_PAGE.REGISTER_PASSWORD_PLACEHOLDER')"
      :error="
        state.password.message ? t(state.password.message, { n: MIN_PASSWORD_LENGTH }) : undefined
      "
      autocomplete="current-password"
      size="lg"
      test="register-form-password-input"
    />
    <template v-if="extendedRegistration">
      <MpSelect
        v-model="values.educationLevel"
        :label="$t('SIGN_UP_PAGE.REGISTER_EDUCATION_LEVEL_LABEL')"
        :placeholder="$t('SIGN_UP_PAGE.REGISTER_EDUCATION_LEVEL_PLACEHOLDER')"
        :options="educationLevels ?? []"
        :get-value="(i) => i.id"
        :get-label="(i) => i.name"
        size="lg"
      />
      <MpInput
        v-model="values.address"
        :label="$t('SIGN_UP_PAGE.REGISTER_LOCATION_LABEL')"
        :placeholder="$t('SIGN_UP_PAGE.REGISTER_LOCATION_PLACEHOLDER')"
        :error="state.address.message ? t(state.address.message) : undefined"
        autocomplete="address-line1"
        size="lg"
      />
    </template>
    <MpField :error="state.acceptTos.message">
      <MpCheckbox
        v-model="values.acceptTos"
        data-test="register-form-terms-checkbox"
      >
        <template #label>
          <AuthPartsDisclaimer
            :text="$t('SIGN_UP_PAGE.DISCLAIMER.PREFIX_REGISTER')"
            :centered="false"
          />
        </template>
      </MpCheckbox>
    </MpField>
    <MpButton
      type="submit"
      size="lg"
      test="register-form-submit-button"
      :text="$t('SIGN_UP_PAGE.REGISTER_BUTTON_LABEL')"
      :is-loading="userStore.registerState === 'inProgress'"
      :disabled="userStore.registerState === 'inProgress'"
    />
  </form>
</template>

<style scoped lang="scss">
  .form {
    display: flex;
    flex-direction: column;
    gap: var(--mp-space-50);

    &__back {
      display: flex;
      align-items: center;
      gap: var(--mp-space-50);
      cursor: pointer;
      margin-bottom: var(--mp-space-70);
    }
  }
</style>
