import { $Fetch } from 'nitropack';
import { AsyncData } from '#app';
import { Paginated } from '@borg/types';
import { isNumber } from '@borg/utils';
import { apiFetch } from './fetch';

type QueryObject = Record<string, MaybeRef<unknown>>;
type ADReturn<T> = AsyncData<T | null, Error | null>;
type ID = number | string;
type URL = string | (() => string);
type HttpMethods = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
type Cache = 'always' | number;
type CallReturnType<T> = T | T[] | Paginated<T> | void;
type FetchType = 'GET' | 'GET-MANY' | 'GET-MANY-PAGINATED';
type Args<T> = {
  url: URL;
  data?: T | Partial<T>;
  query?: QueryObject;
  page?: Ref<number>;
  immediate?: boolean;
  lazy?: boolean;
  /**
   * If number provided = seconds
   */
  cache?: Cache;
};

export class Repository<Entity> {
  get?(...args: unknown[]): ADReturn<Entity>;

  getMany?(...args: unknown[]): ADReturn<Entity[]>;

  getManyPaginated?(page: Ref<ID>, ...args: unknown[]): ADReturn<Paginated<Entity>>;

  create?(data: Entity): Promise<Entity | null>;

  save?(data: Entity): Promise<Entity | null>;

  update?(data: Entity): Promise<Partial<Entity> | null>;

  delete?(id: string | number): Promise<void>;

  private fetch: $Fetch;
  private getKey: string = '';
  private getManyKey: string = '';
  private getManyPaginatedKey: string = '';
  private cacheTime = {
    'GET': new Date(),
    'GET-MANY': new Date(),
    'GET-MANY-PAGINATED': new Date(),
  };

  constructor(fetch = apiFetch) {
    this.fetch = fetch;
  }

  private resolveUseData(lastFetchType: FetchType) {
    if (lastFetchType === 'GET') {
      return this.use().value;
    } else if (lastFetchType === 'GET-MANY') {
      return this.useMany().value;
    }

    return this.useManyPaginated().value;
  }

  private resolveCache<ReturnT extends Entity | Entity[] | Paginated<Entity>>(
    cache: Cache,
    lastFetchType: FetchType,
  ): ReturnT | null {
    const data = this.resolveUseData(lastFetchType);

    if (useNuxtApp().isHydrating) {
      return data;
    }

    if (cache === 'always') {
      return data;
    } else if (isNumber(cache)) {
      if (!this.cacheTime[lastFetchType]) {
        this.cacheTime[lastFetchType] = new Date();
      } else {
        const now = new Date();
        const diff = now.getTime() - this.cacheTime[lastFetchType].getTime();
        const seconds = diff / 1000;
        if (seconds < cache) {
          return data;
        } else {
          this.cacheTime[lastFetchType] = now;
        }
      }
    }

    return null;
  }

  private getWatchableValues(query?: QueryObject) {
    return Object.values(query ?? {}).filter(isRef);
  }

  private unpackQuery(query?: QueryObject) {
    return Object.entries(query ?? {}).reduce((acc, [key, value]) => {
      return { ...acc, [key]: isRef(value) ? value.value : value };
    }, {});
  }

  private resolveUrl(url: URL) {
    return typeof url === 'function' ? url() : url;
  }

  private resolveKey(params: { url: URL; lastFetchType?: FetchType; query?: QueryObject }) {
    if (params.lastFetchType === 'GET') {
      return this.getKey;
    } else if (params.lastFetchType === 'GET-MANY') {
      return this.getManyKey;
    } else if (params.lastFetchType === 'GET-MANY-PAGINATED') {
      return this.getManyPaginatedKey;
    }

    const lang = useNuxtApp().$i18n.locale.value;
    const _query = params.query
      ? '?' + new URLSearchParams(this.unpackQuery(params.query)).toString()
      : '';

    return `${lang}${this.resolveUrl(params.url)}${_query}`;
  }

  private call<
    ReturnT extends CallReturnType<Entity>,
    CacheT extends Exclude<ReturnT, void> = Exclude<ReturnT, void>,
  >(params: {
    method: HttpMethods;
    url: URL;
    fetchType: FetchType;
    query?: QueryObject;
    data?: unknown;
    lazy?: boolean;
    immediate?: boolean;
    cache?: Cache;
  }) {
    const { method, url, fetchType, query, data, cache, lazy = true, immediate = true } = params;
    const key = this.resolveKey({ url, lastFetchType: fetchType, query });
    const watch = this.getWatchableValues(query);
    const fetch = () => {
      return this.fetch<ReturnT>(this.resolveUrl(url), {
        method,
        body: data as Record<string, unknown>,
        query: this.unpackQuery(query),
      });
    };

    return useAsyncData(key, fetch, {
      watch,
      lazy,
      immediate,
      getCachedData: cache ? () => this.resolveCache<CacheT>(cache, fetchType) : undefined,
    });
  }

  protected _get<T extends Entity>(args: Args<T>) {
    this.getKey = this.resolveKey({ url: args.url, query: args.query });
    return this.call<T>({
      method: 'GET',
      url: args.url,
      fetchType: 'GET',
      query: args.query,
      data: args.data,
      lazy: args.lazy,
      immediate: args.immediate,
      cache: args.cache,
    });
  }

  protected _getMany<T extends Entity[]>(args: Args<T>) {
    this.getManyKey = this.resolveKey({ url: args.url, query: args.query });
    return this.call<T>({
      method: 'GET',
      url: args.url,
      fetchType: 'GET-MANY',
      query: args.query,
      data: args.data,
      lazy: args.lazy,
      immediate: args.immediate,
      cache: args.cache,
    });
  }

  protected _getManyPaginated<T extends Paginated<Entity>>(args: Args<T>) {
    this.getManyPaginatedKey = this.resolveKey({ url: args.url, query: args.query });
    const query = { ...args.query, page: args.page };
    return this.call<T>({
      method: 'GET',
      url: args.url,
      fetchType: 'GET-MANY-PAGINATED',
      query,
      data: args.data,
      lazy: args.lazy,
      immediate: args.immediate,
      cache: args.cache,
    });
  }

  protected _create<T extends Entity>(args: Args<T>) {
    return this.fetch<T>(this.resolveUrl(args.url), { method: 'POST', body: args.data });
  }

  protected _save<T extends Entity>(args: Args<T>) {
    return this.fetch<T>(this.resolveUrl(args.url), { method: 'PUT', body: args.data });
  }

  protected _update<T extends Partial<Entity>>(args: Args<T>) {
    return this.fetch<T>(this.resolveUrl(args.url), { method: 'PATCH', body: args.data });
  }

  protected _delete<T extends void>(args: Args<T>) {
    return this.fetch<T>(this.resolveUrl(args.url), { method: 'DELETE' });
  }

  use() {
    const { data } = useNuxtData<Entity>(this.getKey);
    return data;
  }

  useMany() {
    const { data } = useNuxtData<Entity[]>(this.getManyKey);
    return data;
  }

  useManyPaginated() {
    const { data } = useNuxtData<Paginated<Entity>>(this.getManyPaginatedKey);
    return data;
  }
}
