<script setup lang="ts">
  import { MpSocialButton } from '@borg/ui';
  import { checkGoogleGsiClientScript } from '@/utils/googleAccount';

  const projectConfig = useProjectConfig();
  const { $gtag } = useNuxtApp();
  const userStore = useUserStore();

  const emit = defineEmits<{
    (event: 'success'): void;
  }>();

  const scriptPresent = ref(false);
  const googleButtonWrapper = ref();
  const isButtonDisabled = computed(
    () =>
      !scriptPresent.value ||
      userStore.loginState === 'inProgress' ||
      userStore.initState === 'inProgress',
  );

  async function googleLoginCallback(response: google.accounts.id.CredentialResponse) {
    await userStore.googleLogin({ accessToken: response.credential });
    if (userStore.isAuthenticated) {
      $gtag?.logUser('auth:google-login');
      emit('success');
    }
  }

  function initializeGoogleApi() {
    google.accounts.id.initialize({
      client_id: projectConfig.googleClientId,
      auto_select: true,
      callback: googleLoginCallback,
      ux_mode: 'popup',
      itp_support: true,
    });
  }

  function createFakeGoogleWrapper() {
    const googleLoginWrapper = document.createElement('div');
    // Or you can simply hide it in CSS rule for custom-google-button
    googleLoginWrapper.style.display = 'none';
    googleLoginWrapper.classList.add('custom-google-button');
    // Add the wrapper to body
    document.body.appendChild(googleLoginWrapper);
    // Use GSI javascript api to render the button inside our wrapper
    // You can ignore the properties because this button will not appear
    google.accounts.id.renderButton(googleLoginWrapper, { type: 'icon' });

    const googleLoginWrapperButton = googleLoginWrapper.querySelector('div[role=button]');
    return {
      click: () => {
        googleLoginWrapperButton instanceof HTMLElement
          ? googleLoginWrapperButton.click()
          : console.warn('googleLoginWrapperButton HTMLElement not found');
      },
    };
  }

  function handleGoogleLogin() {
    googleButtonWrapper.value.click();
    // This will open GSI login and after success you will have
    // a response on googleLoginCallback method previously created
  }

  onMounted(async () => {
    scriptPresent.value = await checkGoogleGsiClientScript();
    if (scriptPresent.value) {
      initializeGoogleApi();
      googleButtonWrapper.value = createFakeGoogleWrapper();
    }
  });
</script>

<template>
  <MpSocialButton
    brand="google"
    :disabled="isButtonDisabled"
    @click="handleGoogleLogin"
  />
</template>
