<script lang="ts" setup>
  import { MpSocialButton } from '@borg/ui';

  const emit = defineEmits<{
    (event: 'success'): void;
  }>();

  const projectConfig = useProjectConfig();
  const userStore = useUserStore();

  const isLoginInProgress = computed(
    () => userStore.loginState === 'inProgress' || userStore.initState === 'inProgress',
  );

  function onFacebookLogin() {
    FB.login(
      function (loginResponse: facebook.StatusResponse) {
        if (loginResponse.status === 'connected' && loginResponse.authResponse.accessToken) {
          userStore
            .facebookLogin({
              accessToken: loginResponse.authResponse.accessToken,
              userId: loginResponse.authResponse.userID,
            })
            .then(() => {
              if (userStore.loginState === 'isSuccess') {
                emit('success');
              }
            });
        } else {
          console.log('User cancelled login or did not fully authorize.');
        }
      },
      { scope: 'email' },
    );
  }

  function initializeFacebookApi() {
    if (typeof FB === 'undefined') {
      return;
    }
    FB.init({
      appId: projectConfig.facebookAppId,
      // cookie: true, // Enable cookies to allow the server to access the session.
      version: 'v16.0', // Use this Graph API version for this call.
    });
  }

  onMounted(() => {
    initializeFacebookApi();
  });
</script>

<template>
  <MpSocialButton
    brand="facebook"
    :disabled="isLoginInProgress"
    @click="onFacebookLogin"
  />
</template>
