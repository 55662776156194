type ImageKey = string;
type ImageRoute = 'default' | string;
type ImageVersions = {
  desktopLight: string;
  desktopDark: string;
  mobileLight: string;
  mobileDark: string;
};
export type ImagesConfig = Record<ImageKey, Record<ImageRoute, Partial<ImageVersions>>>;

export function useImages() {
  const config = useRuntimeConfig();
  return getImagesConfig(config.public.project.parameters.imagePaths);
}

function getImagesConfig(imagePaths: string[]) {
  return imagePaths.reduce<ImagesConfig>((acc, path) => {
    const filename = path.split('/').pop();
    if (!filename) {
      return acc;
    }
    const [base] = filename.split('.');

    const parts = base.split('--');
    const basename = parts[0];

    const route = parts.find((p) => p.match(/^r-/))?.replace(/^r-/, '') ?? 'default';

    const isDark = parts.includes('dark');
    const isMobile = parts.includes('mobile');

    if (!acc[basename]) {
      acc[basename] = {};
    }
    if (!acc[basename][route]) {
      acc[basename][route] = {};
    }

    if (isMobile && isDark) {
      acc[basename][route]['mobileDark'] = path;
    } else if (isMobile) {
      acc[basename][route]['mobileLight'] = path;
    } else if (isDark) {
      acc[basename][route]['desktopDark'] = path;
    } else {
      acc[basename][route]['desktopLight'] = path;
    }
    return acc;
  }, {});
}
