import { EducationLevel } from '@borg/types';

export const injectionKey: InjectionKey<ReturnType<typeof useAuthForm>> = Symbol('AuthForm');

export function provideAuthForm() {
  const authForm = useAuthForm();
  provide(injectionKey, authForm);

  return authForm;
}

export function injectAuthForm() {
  const authForm = inject(injectionKey);

  if (!authForm) {
    throw new Error('AuthForm is not provided');
  }

  return authForm;
}

export interface AuthFormConfig {
  tab: 'login' | 'register' | 'verify' | 'forgotten_password' | 'new_password';
  previousTab?: AuthFormConfig['tab'];
  showLogo: boolean;
}

export interface AuthFormData {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  rememberMe: boolean;
  educationLevel: EducationLevel | undefined;
  address: string;
}

const defaultConfig: AuthFormConfig = {
  tab: 'login',
  previousTab: undefined,
  showLogo: true,
};

const defaultData: AuthFormData = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  rememberMe: false,
  educationLevel: undefined,
  address: '',
};

function useAuthForm() {
  const router = useRouter();
  const route = useRoute();
  const config = ref<AuthFormConfig>({ ...defaultConfig });
  const data = ref<AuthFormData>({ ...defaultData });

  onMounted(() => {
    const viewTab = route.query.view;
    if (viewTab && viewTab !== config.value.tab) {
      setConfig({ tab: viewTab as AuthFormConfig['tab'] });
    }
  });

  watch(
    () => route.query,
    (query) => {
      const viewTab = query.view;
      if (viewTab && viewTab !== config.value.tab) {
        setConfig({ tab: viewTab as AuthFormConfig['tab'] });
      }
    },
  );

  function setTab(tab: AuthFormConfig['tab']) {
    router.push({ query: { ...route.query, view: tab } });
  }

  function setConfig(values: Partial<AuthFormConfig>) {
    config.value = { ...config.value, ...values };
  }

  function setData(values: Partial<AuthFormData>) {
    data.value = { ...data.value, ...values };
  }

  function reset() {
    config.value = { ...defaultConfig };
    data.value = { ...defaultData };
  }

  return reactive({
    config,
    data,
    setTab,
    setConfig,
    setData,
    reset,
  });
}
