import type { Ref } from 'vue';
import { defineStore } from 'pinia';
import type { AsyncState, Paginated, ServiceResult, WithPaginationParams } from '@borg/types';
import { isArray } from '@borg/utils';

interface ItemStoreSetup<T, P = unknown> {
  name: string;
  loadService: (params: P) => ServiceResult<T>;
  cached?: boolean;
}

interface ListStoreSetup<T> {
  name: string;
  loadService: () => ServiceResult<T[]>;
  removeService?: (id: number) => ServiceResult<void>;
  cached?: boolean;
}

interface PaginatedListStoreSetup<T> {
  name: string;
  loadService: (params: WithPaginationParams) => ServiceResult<Paginated<T>>;
  removeService?: (id: number) => ServiceResult<void>;
}

export function listStoreFactory<T>(setup: ListStoreSetup<T> | PaginatedListStoreSetup<T>) {
  return defineInitStore(setup.name, () => {
    const items = ref<T[]>([]) as Ref<T[]>;
    const total = ref(0);
    const totalPages = ref(0);
    const page = ref(1);
    const loadState = ref<AsyncState>('isInitial');
    const removeState = ref<AsyncState>('isInitial');
    const lastLanguage = ref<string>();
    const isLoading = computed(() => loadState.value === 'inProgress');

    async function init() {
      page.value = 1;
      await load();
    }

    function setPage(pageNumber: number) {
      page.value = pageNumber;
      load();
    }

    async function load() {
      const currentLanguage = useNuxtApp().$i18n.locale.value ?? '';
      // todo - maybe do better
      if (
        loadState.value === 'isSuccess' &&
        'cached' in setup &&
        setup.cached &&
        lastLanguage.value === currentLanguage
      ) {
        return;
      }
      try {
        loadState.value = 'inProgress';
        const result = await setup.loadService({ page: page.value });
        if (result.isSuccess) {
          loadState.value = 'isSuccess';
          lastLanguage.value = currentLanguage;
          if (isArray(result.data)) {
            items.value = result.data;
          } else {
            if (page.value > 1 && result.data.totalPages < page.value) {
              setPage(result.data.totalPages);
              return;
            }
            items.value = result.data.items;
            total.value = result.data.total;
            totalPages.value = result.data.totalPages;
          }
        } else {
          loadState.value = 'isInvalid';
        }
      } catch (error) {
        loadState.value = 'hasError';
        console.error(`Oops! Error in load() ${setup.name}`, error);
      }
    }

    async function remove(id: number) {
      if (!setup.removeService) {
        console.error(`Oops! 'removeService' note defined for ${setup.name}`);
        return;
      }
      try {
        removeState.value = 'inProgress';
        const result = await setup.removeService(id);
        if (result.isSuccess) {
          removeState.value = 'isSuccess';
        } else {
          removeState.value = 'isInvalid';
        }
      } catch (error) {
        removeState.value = 'hasError';
        console.error(`Oops! Error in remove() ${setup.name}`, error);
      }
    }

    return {
      items,
      total,
      totalPages,
      page,
      loadState,
      removeState,
      lastLanguage,
      isLoading,
      init,
      setPage,
      remove,
    };
  });
}

export function itemStoreFactory<T, P = number>(setup: ItemStoreSetup<T, P>) {
  return defineInitStore(setup.name, () => {
    const item = ref<T>();
    const loadState = ref<AsyncState>('isInitial');
    const lastLanguage = ref<string>();
    const isLoading = computed(() => loadState.value === 'inProgress');
    const hasError = computed(
      () => loadState.value === 'isInvalid' || loadState.value === 'hasError',
    );

    async function init(params: P) {
      const currentLanguage = useNuxtApp().$i18n.locale.value ?? '';
      // todo - maybe do better
      // todo - !!WARNING!! - does not take params into account for now - do some 'cache-hash' generation
      if (
        loadState.value === 'isSuccess' &&
        'cached' in setup &&
        setup.cached &&
        lastLanguage.value === currentLanguage
      ) {
        return;
      }
      try {
        loadState.value = 'inProgress';
        item.value = undefined as T;
        const result = await setup.loadService(params);
        if (result.isSuccess) {
          loadState.value = 'isSuccess';
          lastLanguage.value = currentLanguage;
          item.value = result.data;
        } else {
          loadState.value = 'isInvalid';
        }
      } catch (error) {
        loadState.value = 'hasError';
        throw error;
      }
    }

    function reset() {
      item.value = undefined;
      loadState.value = 'isInitial';
    }

    return {
      item,
      loadState,
      lastLanguage,
      isLoading,
      hasError,
      init,
      reset,
    };
  });
}

export interface InitStore {
  init: (...args: any[]) => Promise<void>;
}

export function defineInitStore<ISS extends InitStore, INIT extends ISS['init']>(
  key: string,
  storeSetup: () => ISS,
) {
  return defineStore(key, () => {
    const store = storeSetup();

    const initWrapper = async (...args: Parameters<INIT>) => {
      if (process.server) {
        await store.init(...args);
      }

      if (process.client && !useNuxtApp().isHydrating) {
        await store.init(...args);
      }
    };

    const lazyInitWrapper = async (...args: Parameters<INIT>) => {
      if (process.server) {
        await store.init(...args);
      }

      if (process.client && !useNuxtApp().isHydrating) {
        store.init(...args);
      }
    };

    return {
      ...store,
      init: initWrapper,
      lazyInit: lazyInitWrapper,
    };
  });
}
