import type { JwtPayload, JwtResponse, Login, Register, ServiceResult, Verify } from '@borg/types';
import { AuthFailCode } from '@/types';

class AuthService {
  login: (data: Login) => ServiceResult<JwtPayload, AuthFailCode> = async (data: Login) => {
    const result = await http.post<JwtPayload>('/auth/login', data);
    if (result.status >= 200 && result.status < 300) {
      return { isSuccess: true, data: result.data };
    }
    if (result.status === 404) {
      return { isSuccess: false, message: result.statusText, code: 'NOT_FOUND' };
    }
    if (result.status === 400) {
      return { isSuccess: false, message: result.statusText, code: 'INVALID_CREDENTIALS' };
    }
    return { isSuccess: false, message: `Could not 'POST '/auth/login` };
  };
  logout = () => serviceHttpDeleteFactory('/auth/logout');
  loginWithCode = (data: { code: string }) => serviceHttpPostFactory<JwtPayload>('/auth', data);
  loginWithToken = (data: { token: string }) =>
    serviceHttpPostFactory<JwtPayload>('/auth/login-with-token', data);
  facebookLogin = async (data: {
    accessToken: string;
    userId: string;
  }): ServiceResult<JwtPayload, AuthFailCode> => {
    const result = await http.post<JwtPayload>('/auth/facebook/redirect', data);
    if (result.status >= 200 && result.status < 300) {
      return { isSuccess: true, data: result.data };
    }
    return { isSuccess: false, message: result.statusText, code: 'LOGIN_ERROR' };
  };
  googleLogin = async (data: { accessToken: string }): ServiceResult<JwtPayload, AuthFailCode> => {
    const result = await http.post<JwtPayload>('/auth/google/redirect', data);
    if (result.status >= 200 && result.status < 300) {
      return { isSuccess: true, data: result.data };
    }
    return { isSuccess: false, message: result.statusText, code: 'LOGIN_ERROR' };
  };
  register = async (data: Register): ServiceResult<JwtPayload, AuthFailCode> => {
    const result = await http.post<JwtPayload>('/auth/register', data);
    if (result.status >= 200 && result.status < 300) {
      return { isSuccess: true, data: result.data };
    }
    if (result.status === 409) {
      return { isSuccess: false, message: result.statusText, code: 'USER_EXISTS' };
    }
    return { isSuccess: false, message: `Could not 'POST' /auth/register` };
  };
  verify = (data: Verify) => serviceHttpPostFactory<JwtPayload>('/auth/verify', data);
  verifyEmail = (data: Verify) => serviceHttpPostFactory<JwtPayload>('/auth/verify-email', data);
  getAnonToken = () => serviceHttpGetFactory<JwtResponse>('/me/token');
  sendMeta = (data: Record<string, string>) => serviceHttpPostFactory<void>('/me/meta', data);
  requestForgottenPassword = (data: { email: string }) =>
    serviceHttpPostFactory<void>('/auth/password', data);
  verifyForgottenPasswordHash = (data: { hash: string }) =>
    serviceHttpGetFactory<void>(`/auth/password/${data.hash}`);
  setForgottenPassword = (data: { hash: string; password: string }) =>
    serviceHttpPostFactory<JwtPayload>(`/auth/password/${data.hash}`, { password: data.password });
}

export const authService = new AuthService();
