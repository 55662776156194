<script setup lang="ts">
  import { MpButton, MpDivider, MpInput, MpSpinner, MpText, pushToastMessage } from '@borg/ui';
  import { RequestPasswordChangeSchema } from '@borg/validation';
  import { injectAuthForm } from '../useAuthForm';

  const { t } = useI18n();
  const authForm = injectAuthForm();
  const userStore = useUserStore();
  const requestSentSuccessfullyOnce = ref(false);
  const { state, values, validate } = useForm({
    initialData: () => ({
      email: '',
    }),
    schema: RequestPasswordChangeSchema,
  });

  async function onSubmit() {
    const valid = await validate();
    if (valid) {
      await userStore.requestForgottenPassword({ email: values.email });
      if (userStore.requestForgottenPasswordState === 'isSuccess') {
        requestSentSuccessfullyOnce.value = true;
        pushToastMessage({
          type: 'success',
          title: t('SIGN_UP_PAGE.FORGOTTEN_PASSWORD_SUCCESS_TOAST.TITLE'),
          subtitle: t('SIGN_UP_PAGE.FORGOTTEN_PASSWORD_SUCCESS_TOAST.SUBTITLE'),
        });
      } else {
        pushToastMessage({
          type: 'alert',
          title: t('SIGN_UP_PAGE.FORGOTTEN_PASSWORD_ERROR_TOAST.TITLE'),
          subtitle: t('SIGN_UP_PAGE.FORGOTTEN_PASSWORD_ERROR_TOAST.SUBTITLE'),
        });
      }
    }
  }

  function onCancel() {
    authForm.setTab('login');
  }

  onBeforeRouteLeave(() => {
    requestSentSuccessfullyOnce.value = false;
  });
</script>

<template>
  <form
    class="form"
    @submit.prevent="onSubmit"
    @reset.prevent="onCancel"
  >
    <template v-if="!requestSentSuccessfullyOnce">
      <MpText
        as="h3"
        no-margin
      >
        {{ $t('SIGN_UP_PAGE.FORGOTTEN_PASSWORD_TITLE') }}
      </MpText>
      <MpText no-margin>
        {{ $t('SIGN_UP_PAGE.FORGOTTEN_PASSWORD_DESCRIPTION') }}
      </MpText>
      <MpInput
        v-model="values.email"
        size="lg"
        :label="$t('SIGN_UP_PAGE.FORGOTTEN_PASSWORD_EMAIL_LABEL')"
        :disabled="userStore.requestForgottenPasswordState === 'inProgress'"
        :error="state.email.message ? t(state.email.message) : undefined"
        autocomplete="username"
        test="forgotten-password-form-email"
      />
      <div class="form__action-buttons">
        <MpButton
          type="reset"
          size="lg"
          :text="$t('SIGN_UP_PAGE.FORGOTTEN_PASSWORD_CANCEL_BUTTON_LABEL')"
          variant="ghost"
          test="forgotten-password-form-back"
        />
        <MpButton
          type="submit"
          size="lg"
          :text="$t('SIGN_UP_PAGE.FORGOTTEN_PASSWORD_CONFIRM_BUTTON_LABEL')"
          :is-loading="userStore.requestForgottenPasswordState === 'inProgress'"
          :disabled="userStore.requestForgottenPasswordState === 'inProgress'"
          test="forgotten-password-form-submit"
        />
      </div>
    </template>
    <template v-else>
      <MpText no-margin>
        {{ $t('SIGN_UP_PAGE.FORGOTTEN_PASSWORD_SUCCESS_TITLE') }}
      </MpText>
      <MpText
        as="h3"
        no-margin
      >
        {{ values.email }}
      </MpText>
      <MpDivider margin="sm" />
      <MpSpinner
        v-if="userStore.requestForgottenPasswordState === 'inProgress'"
        style="margin: auto"
      />
      <MpText
        v-else
        as="p"
        no-margin
        class="form__disclaimer"
      >
        {{ $t('SIGN_UP_PAGE.FORGOTTEN_PASSWORD_SUCCESS_UNDELIVERED_DESCRIPTION.1') }}
        <MpText
          bold
          style="text-decoration: underline; cursor: pointer"
          test="forgotten-password-form-resubmit"
          @click="onSubmit"
        >
          {{ $t('SIGN_UP_PAGE.FORGOTTEN_PASSWORD_SUCCESS_UNDELIVERED_DESCRIPTION.2') }}
        </MpText>
        .
      </MpText>
    </template>
  </form>
</template>

<style scoped lang="scss">
  .form {
    display: flex;
    flex-direction: column;
    gap: var(--mp-space-50);

    &__disclaimer {
      padding: 0 20%;
      text-align: center;
    }

    &__action-buttons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: var(--mp-space-30);
      margin-top: var(--mp-space-30);
    }
  }
</style>
