<script setup lang="ts">
  useHead({
    script: [
      {
        src: 'https://accounts.google.com/gsi/client',
        async: true,
        defer: true,
      },
    ],
  });
</script>

<template>
  <span v-if="false" />
</template>
